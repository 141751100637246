<template>
  <div class="content-container">
    <div class="slider-wrapper pb-10">
      <div class="controls relative text-white-1 hidden xs:block">
        <button name="prev"
          class="z-10 absolute left-arrow-btn rounded-full w-8 h-8 lg:w-10 lg:h-10 lg:-left-8 xl:-left-10 items-center bg-pink-3 flex justify-center top-28 focus:outline-none"
          @click="prev()"
        >
          <i class="fas fa-chevron-left" />
        </button>
        <button
        name="next"
          class="z-10 absolute right-arrow-btn rounded-full right-0 lg:-right-8 xl:-right-10 w-8 h-8 lg:w-10 lg:h-10 items-center bg-pink-3 flex justify-center top-28 focus:outline-none"
          @click="next()"
        >
          <i class="fas fa-chevron-right" />
        </button>
      </div>
      <Slick ref="slick" :options="slickOptions">
        <div v-for="(q, i) in testimonials" :key="i">
          <div class="card p-4 w-72 sm:w-80 mx-auto h-60 sm:h-64">
            <div
              class="bg-white-2 shadow-custom rounded-md h-full flex flex-col justify-center p-3 relative"
            >
              <div class="qoute">
                <img
                  class="w-8"
                  src="../../assets/images/home-page/quotationmark2.png"
                  alt=""
                />
              </div>
              <div>
                <p class="text-green-1 italic">
                  {{ q.qoute }}
                </p>
              </div>
              <div>
                <h4 class="text-lg mt-3 font-semibold text-green-1">
                  {{ q.name }}
                </h4>
              </div>
            </div>
          </div>
        </div>
      </Slick>
    </div>
  </div>
</template>

<script>
import Slick from 'vue-slick'
export default {
  components: {
    Slick,
  },
  data() {
    return {
      slickOptions: {
        slidesToShow: 3,
        infinite: true,
        arrows: false,
        dots: false,
        // autoplay: true,
        autoplaySpeed: 5000,
        // Any other options that can be got from plugin documentation
        responsive: [
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
            },
          },
        ],
      },
      testimonials: [
        {
          name: 'Tyra Crysostomo',
          qoute:
            'Actually and bilis lang, parang below ten minutes lang. Kung masakit ba, nasaktan ako ng very very konti lang kasi ngayon lang ulit ako...',
        },
        {
          name: 'Teena Rose',
          qoute:
            'It was NOT as I expected it to be, it was quite a nice experience. If you are scared and would want a pleasant experience try Lay Bare...',
        },
        {
          name: 'Say Tioco Artillero',
          qoute:
            'I am here at Lay Bare... Lay Bare all the way! I am not getting paid to do this, pero super love ko lang ito. Ilang years na rin ako sa Lay Bare...',
        },
        {
          name: 'Lou Sanchez',
          qoute:
            'First time ko ate, wag mo iju-judge ha?...Tyaka ang bilis lang ano ate konting kurot  lang tapos na. Thank you Lay Bare!',
        },
        {
          name: 'Phoebe Ann',
          qoute:
            'So finally, I booked myself an appointment at Lay Bare Bonifacio Stopover to get my cold bikini wax done! I did not have any swimming...',
        },
      ],
    }
  },
  methods: {
    next() {
      this.$refs.slick.next()
    },

    prev() {
      this.$refs.slick.prev()
    },
  },
}
</script>

<style></style>
