<template>
  <div>
    <div class="relative">
      <div class="w-full bg-pink-2 h-28 lg:h-36 bottom-0 absolute" />
      <div class="max-w-screen-lg mx-auto px-4 z-10">
        <div class="grid grid-cols-2 md:grid-cols-4 gap-x-5 justify-items-center mt-10 gap-2" id="user-feedback">
          <div class="text-green-3 text-center">
            <img class="w-24 lg:w-32 mx-auto" src="../assets/images/download-page/icon1.png" alt="Icon" />
            <h4 class="font-semibold text-sm lg:text-xl my-3">
              Book an appointment
            </h4>
            <p class="text-xs lg:text-base">
              Skip the long lines! The app lets you book an appointment at your
              preffered branch and time, even your favorite staff. Also, in our
              new brands.
            </p>
          </div>
          <div class="text-green-3 text-center">
            <img
              class="w-24 lg:w-32 mx-auto"
              src="../assets/images/download-page/icon2.png"
              alt="Icon"
            />
            <h4 class="font-semibold text-sm lg:text-lg my-3">
              Are you certified loyalista?
            </h4>
            <p class="text-xs lg:text-base">
              Track your visits and monitor your progress towards becoming a PLC
              member to avail of your exclusive discounts.
            </p>
          </div>
          <div class="text-green-3 text-center">
            <img
              class="w-24 lg:w-32 mx-auto"
              src="../assets/images/download-page/icon3.png"
              alt="Icon"
            />
            <h4 class="font-semibold text-sm lg:text-xl my-3">
              Rate and review
            </h4>
            <p class="text-xs lg:text-base">
              Let us know what you loved about your service! Our newest app
              allows you to rate your overall experience with us!
            </p>
          </div>
          <div class="text-green-3 text-center">
            <img
              class="w-24 lg:w-32 mx-auto"
              src="../assets/images/download-page/icon4.png"
              alt="Icon"
            />
            <h4 class="font-semibold text-sm lg:text-xl my-3">
              App-Only offers
            </h4>
            <p class="text-xs lg:text-base">
              Visit the app for exclusive deals, promotions, and early launches.
            </p>
          </div>
        </div>

        <div class="testimonials mt-10" id="app-benefits">
          <div class="text-center">
            <h3 class="font-semibold text-green-1 lg:text-xl mb-5">
              Trusted by 20,000+ happy customers in the Philippines
            </h3>
          </div>

          <div class="carousel">
            <Testimonials />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Testimonials from '../components/app/Testimonials.vue'
export default {
  components: {
    Testimonials,
  },
}
</script>

<style>

</style>